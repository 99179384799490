
import React from 'react';
import { Box, Card, Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// Commenting out until we work on SP workflow
// import { CheckCircle, Warning, ExpandMore, ExpandLess } from '@mui/icons-material';
// import { green, amber } from '@mui/material/colors';
import theme from '../../styles/theme';

const getCodeCardStyle = (code, aiIdentifiedConditions = [], userSelectedCodes = []) => {
    const isAIIdentified = aiIdentifiedConditions?.some(aiCondition =>
        aiCondition.codes && aiCondition.codes.some(aiCode => aiCode?.code === code?.code)
    );

    // If not found in AI-identified conditions, consider it user-selected
    const isUserSelected = !isAIIdentified || userSelectedCodes.some(userCode => userCode.code === code.code);

    if (isUserSelected) {
        return {
            backgroundColor: theme.palette.codes.user.background,
            color: theme.palette.codes.user.color,
        };
    } else if (isAIIdentified) {
        return {
            backgroundColor: theme.palette.codes.ai.background,
            color: theme.palette.codes.ai.color,
        };
    } else {
        return {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
        };
    }
};

const CodeCard = ({
    code,
    action,
    chevronLeft = false,
    aiIdentifiedConditions = [],
    userSelectedCodes = [],
    reviewResult,
}) => {
    // Determine if the code is appropriate
    // const isAppropriate = reviewResult
    //     ? ['Definitely', 'Probably'].includes(reviewResult.Appropriate)
    //     : null;

    // State to control visibility of the rationale
    // const [showRationale, setShowRationale] = useState(false);

    // Function to toggle rationale visibility
    // const toggleRationale = (e) => {
    //     e.stopPropagation(); // Prevent triggering other onClick events
    //     setShowRationale((prev) => !prev);
    // };
    code.reviewResult = reviewResult;

    return (
        <Card key={code.code} sx={{ marginTop: 0, marginBottom: 0, padding: 0, display: 'flex' }}>
            <Box sx={{ width: 8, ...getCodeCardStyle(code, aiIdentifiedConditions, userSelectedCodes) }} />
            <Box
                onClick={() => action(code)}
                sx={{
                    flex: 1,
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    padding: 1,
                }}
            >
                {chevronLeft && <ChevronLeftIcon />}
                <Box sx={{ flex: 1, marginLeft: chevronLeft ? 2 : 0 }}>
                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                        {code.code}
                    </Typography>
                    <Typography variant="body2">{code.description}</Typography>

                    {/* Display the appropriate icon and text - commented out for initial deploy  */}
                    {/*{reviewResult && (*/}
                    {/*    <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 1 }}>*/}
                    {/*        {isAppropriate ? (*/}
                    {/*            <CheckCircle sx={{ color: green[500], marginRight: 1 }} />*/}
                    {/*        ) : (*/}
                    {/*            <Warning sx={{ color: amber[700], marginRight: 1 }} />*/}
                    {/*        )}*/}
                    {/*        <Typography variant="subtitle2">{reviewResult.Appropriate}</Typography>*/}

                    {/*        <Button*/}
                    {/*            onClick={toggleRationale}*/}
                    {/*            size="small"*/}
                    {/*            sx={{ marginLeft: 'auto', textTransform: 'none' }}*/}
                    {/*            aria-label={showRationale ? 'Hide rationale' : 'Show rationale'}*/}
                    {/*            endIcon={showRationale ? <ExpandLess /> : <ExpandMore />}*/}
                    {/*        >*/}

                    {/*        </Button>*/}
                    {/*    </Box>*/}
                    {/*)}*/}

                    {/*/!* Conditionally render the rationale *!/*/}
                    {/*{reviewResult && showRationale && (*/}
                    {/*    <Typography variant="body2" sx={{ marginTop: 1 }}>*/}
                    {/*        <strong>AI Review Rationale:</strong> {reviewResult.Rationale}*/}
                    {/*    </Typography>*/}
                    {/*)}*/}
                </Box>
                {!chevronLeft && <ChevronRightIcon />}
            </Box>
        </Card>
    );
};

export default CodeCard;
