// apis/patient.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const apiUrl = process.env.REACT_APP_API_URL;

const baseQuery = fetchBaseQuery({
    baseUrl: `${apiUrl}/api/`,
    prepareHeaders: (headers, { getState }) => {
        const token = getState().auth.token;
        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
    },
});

export const patientApiSlice = createApi({
    reducerPath: 'patientApi',
    baseQuery,
    endpoints: (builder) => ({
        fetchPatient: builder.query({
            query: (patientId) => {
                const chartSource = localStorage.getItem('selectedChartSource');
                return {
                    url: `patients/${patientId}?chartSource=${chartSource}`,
                    method: 'GET',
                };
            },

        }),
        updatePatient: builder.mutation({
            query: (patient) => {
               const chartSource = localStorage.getItem('selectedChartSource');

                return {
                url: `patients/${patient.uuid}/?chartSource=${chartSource}`,
                method: 'PUT',
                body: patient,
            }},
        }),
    }),
});

export const {
    useFetchPatientQuery,
    useUpdatePatientMutation,
} = patientApiSlice;
