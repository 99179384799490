import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import LandingPage from './Views/LandingPage';
import LoginPage from "./Auth/LoginPage";
import ChartDetails from './Views/ChartDetail';
import PromptsPage from './Prompts/PromptsPage'; // Add this import
import theme from './styles/theme'; // Import the custom theme
import Header from './Views/Header';
import UploadPage from "./Views/UploadPage";
import AdminUsers from "./Views/Admin/AdminUsers";
import {GoogleOAuthProvider} from "@react-oauth/google";

function App() {
    // TODO: replace with env var
    const clientId = '184844522388-p1ch8s8p47lrcpmangvb6cvbjs5hirgm.apps.googleusercontent.com';

    return (
        <GoogleOAuthProvider clientId={clientId}>
            <ThemeProvider theme={theme}>
                <CssBaseline />

                <Router>
                    <div className="App">
                        <header className="App-header">
                            <Header />
                        </header>
                        <main>
                            <Routes>
                                <Route path="/" element={<LandingPage />} />
                                <Route path="/login" element={<LoginPage />} />
                                <Route path="/chart/:id" element={<ChartDetails />} />
                                <Route path="/prompts" element={<PromptsPage />} />
                                <Route path="/integrations" element={<UploadPage />} />
                                <Route path="/users" element={<AdminUsers />} />

                            </Routes>
                        </main>
                    </div>
                </Router>
            </ThemeProvider>
        </GoogleOAuthProvider>
    );
}

export default App;
