import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

async function getCsrfToken() {
    const response = await fetch(`${apiUrl}/csrf/`, {
        credentials: 'include',
    });
    const data = await response.json();
    return data.csrfToken;
}

async function setupCsrf() {
    const csrfToken = await getCsrfToken();
    axios.defaults.headers.common['X-CSRFTOKEN'] = csrfToken;
}

setupCsrf();

export const loginUser = createAsyncThunk('auth/loginUser', async (credentials, { rejectWithValue }) => {
    try {
        const response = await axios.post(`${apiUrl}/auth/login/`, {
            username: credentials.username,
            password: credentials.password,
        }, {
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || { detail: 'Something went wrong' });
    }
});

export const loginWithGoogle = createAsyncThunk(
    'auth/loginWithGoogle',
    async (googleToken, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${apiUrl}/api/auth/google/`, { token: googleToken }, {
                withCredentials: true
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { detail: 'Something went wrong' });
        }
    }
);

const getToken = () => {
    const token = localStorage.getItem('token');
    if (token) {
        try {
            return JSON.parse(token);
        } catch (e) {
            console.error('Error parsing token from localStorage', e);
            localStorage.removeItem('token');
            return null;
        }
    }
    return null;
};

const getUser = () => {
    const user = localStorage.getItem('user');
    if (user) {
        try {
            return JSON.parse(user);
        } catch (e) {
            console.error('Error parsing user from localStorage', e);
            localStorage.removeItem('user'); // Remove invalid user
            return null;
        }
    }
    return null;
};

const initialState = {
    token: getToken(),
    user: getUser(),
    status: 'idle',
    error: null,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout: (state) => {
            state.token = null;
            state.user = null;
            localStorage.removeItem('token');
            localStorage.removeItem('user');
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginUser.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(loginUser.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.token = action.payload.access;
                state.user = action.payload.user;
                localStorage.setItem('token', JSON.stringify(action.payload.access));
                localStorage.setItem('user', JSON.stringify(action.payload.user));
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(loginWithGoogle.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(loginWithGoogle.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.token = action.payload.access;
                state.user = action.payload.user;
                localStorage.setItem('token', JSON.stringify(action.payload.access));
                localStorage.setItem('user', JSON.stringify(action.payload.user));
            })
            .addCase(loginWithGoogle.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;
