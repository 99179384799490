import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Alert, Box, Button, CircularProgress, Snackbar, Typography } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import CodeCard from './CodeCard';
import ProcessingTimeModal from './ProcessingTimeModal';
import { useCreateOrUpdateCodedChartMutation, useFetchChartDetailsQuery } from '../../apis/chart';

const FinalizedCodes = ({
    completedCodes,
    handleRemoveFromCompleted,
    aiIdentifiedConditions,
    userSelectedCodes,
    onDragEnd,
    codeReviewStatus,
    chartId,
}) => {
    const { id } = useParams();
    const [modalOpen, setModalOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('success');
    const [createOrUpdateCodedChart, { isLoading: isLoadingCodedChartMutation }] = useCreateOrUpdateCodedChartMutation();
    const { data: chart, isFetching: isFetchingChart, refetch: refetchDetail } = useFetchChartDetailsQuery(id);

    const codedCharts = chart?.coded_charts || [];
    const humanCodedChart = codedCharts.find(codedChart => codedChart.source === 'Human') || null;

    const handleFinalizeClick = () => {
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const handleModalSubmit = async (time) => {
        try {
            await createOrUpdateCodedChart({
                id: humanCodedChart?.uuid,
                chart: chart.uuid,
                source: 'Human',
                confirmed_codes: completedCodes,
                processing_time: time,
                is_processed: true,
            }).unwrap();
            refetchDetail();
            setModalOpen(false);
            setAlertMessage('Successfully finalized codes');
            setAlertSeverity('success');
        } catch (error) {
            console.error('Failed to finalize codes:', error);
            setAlertMessage('Failed to finalize codes. Please refresh the page and try again.');
            setAlertSeverity('error');
        }
    };

    const handleUnlockCodes = async () => {
        try {
            await createOrUpdateCodedChart({
                id: humanCodedChart?.uuid,
                chart: chart.uuid,
                is_processed: false,
            }).unwrap();
            refetchDetail();
            setAlertMessage('Successfully unlocked codes!');
            setAlertSeverity('success');
        } catch (error) {
            console.error('Failed to unlock codes:', error);
            setAlertMessage('Failed to unlock codes. Please refresh the page and try again.');
            setAlertSeverity('error');
        }
    };

    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: 2, marginBottom: 1 }}>
                <Typography variant="h6">Finalized Codes</Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={humanCodedChart?.is_processed ? handleUnlockCodes : handleFinalizeClick}
                    disabled={isLoadingCodedChartMutation || isFetchingChart}
                    startIcon={isLoadingCodedChartMutation || isFetchingChart ? (<CircularProgress size={14} />) : null}
                >
                    {humanCodedChart?.is_processed ? "Unlock" : "Finalize"}
                </Button>
                <Snackbar
                    open={!!alertMessage}
                    autoHideDuration={3000}
                    onClose={() => setAlertMessage('')}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                    <Alert onClose={() => setAlertMessage('')} severity={alertSeverity} sx={{ width: '100%' }}>
                        {alertMessage}
                    </Alert>
                </Snackbar>
            </Box>
            <Box sx={{ padding: 2, border: '1px solid #ccc', backgroundColor: '#f9f9f9', height: '100%' }}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="completedCodes">
                        {(provided) => (
                            <Box
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                sx={{ minHeight: '80%', overflowY: 'auto' }}
                            >
                                {completedCodes.map((code, index) => {
                                    const codeKey = code.code;
                                    const status = codeReviewStatus[chartId]?.[codeKey];
                                    // const isLoading = status?.isLoading;
                                    const reviewResult = status?.reviewResult;

                                    return (
                                        <Draggable key={codeKey} draggableId={codeKey} index={index}>
                                            {(provided) => (
                                                <Box
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    sx={{ position: 'relative' }}
                                                >
                                                    <CodeCard
                                                        code={code}
                                                        action={handleRemoveFromCompleted}
                                                        chevronLeft
                                                        aiIdentifiedConditions={aiIdentifiedConditions}
                                                        userSelectedCodes={userSelectedCodes}
                                                        reviewResult={reviewResult}
                                                    />
                                                    {/*Commenting out until workflow is established*/}
                                                    {/*{isLoading && (*/}
                                                    {/*    <Box*/}
                                                    {/*        sx={{*/}
                                                    {/*            position: 'absolute',*/}
                                                    {/*            top: 0,*/}
                                                    {/*            left: 0,*/}
                                                    {/*            width: '100%',*/}
                                                    {/*            height: '100%',*/}
                                                    {/*            backgroundColor: 'rgba(255, 255, 255, 0.7)',*/}
                                                    {/*            display: 'flex',*/}
                                                    {/*            justifyContent: 'center',*/}
                                                    {/*            alignItems: 'center',*/}
                                                    {/*            zIndex: 1,*/}
                                                    {/*        }}*/}
                                                    {/*    >*/}
                                                    {/*        <CircularProgress />*/}
                                                    {/*        <Typography variant="body1" sx={{ marginLeft: 1 }}>*/}
                                                    {/*            Analyzing...*/}
                                                    {/*        </Typography>*/}
                                                    {/*    </Box>*/}
                                                    {/*)}*/}
                                                </Box>
                                            )}
                                        </Draggable>
                                    );
                                })}
                                {provided.placeholder}
                            </Box>
                        )}
                    </Droppable>
                </DragDropContext>

                <ProcessingTimeModal
                    open={modalOpen}
                    onClose={handleModalClose}
                    onSubmit={handleModalSubmit}
                />
            </Box>
        </Box>
    );
};

export default FinalizedCodes;
