import React, { useState } from 'react';
import TabbedCharts from './TabbedCharts';
import ChartSourceSelector from "./ChartSourceSelector";
import {Box} from "@mui/material";

const ChartTable = () => {
    const [chartSource, setChartSource] = useState(undefined);

    return (
        <Box sx={{ padding: '10px' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, padding: '10px' }}>
                <ChartSourceSelector chartSource={chartSource} onChartSourceChange={setChartSource} />
            </Box>

            <Box sx={{ padding: '20px' }}> {/* Additional padding for the chart content */}
                <TabbedCharts chartSource={chartSource} />
            </Box>
        </Box>
    );
};

export default ChartTable;
