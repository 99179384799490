import React from 'react';
import { Box, Card, CardContent, Typography, CircularProgress } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import CodeCard from './CodeCard';
import theme from "../../styles/theme";
import ConditionHeader from './ConditionHeader';

const ConditionsList = ({
    aiIdentifiedConditions,
    completedCodes,
    userSelectedCodes,
    handleMoveToCompleted,
    handleCodeClick,
    highlightedText,
    codeReviewStatus,
    chartId,
}) => {
    // Annotate user added codes
    const userAvailableCodes = userSelectedCodes.map(code => ({
        ...code,
        condition: 'User Added',
        highlight_text: '',
        rationale: ''
    }));

    // Combine AI and User added codes
    const combinedAvailableCodes = aiIdentifiedConditions.flatMap(group => group.codes.map(code => ({
        ...code,
        condition: group.condition,
        highlight_text: group.highlight_text,
        rationale: group.rationale
    }))).concat(userAvailableCodes);

    const filteredAvailableCodes = combinedAvailableCodes.filter(code =>
        !completedCodes.some(completedCode => completedCode.code === code.code)
    );


    // Group codes by condition
    const groupedCodes = filteredAvailableCodes.reduce((acc, code) => {
        const condition = code.condition;
        const existingGroup = acc.find(group => group.condition === condition);
        if (existingGroup) {
            existingGroup.codes.push(code);
        } else {
            acc.push({
                condition: condition,
                codes: [code],
                highlight_text: code.highlight_text,
                rationale: code.rationale
            });
        }
        return acc;
    }, []);

    // Separate User Added codes
    const userAddedGroup = groupedCodes.find(group => group.condition === 'User Added');
    const aiGroupedGroups = groupedCodes.filter(group => group.condition !== 'User Added');

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ height: '100%', overflowY: 'auto' }}>
                {/* User Added Codes */}
                {userAddedGroup && userAddedGroup.codes.length > 0 && (
                    <Card key="User Added">
                        <CardContent>
                            <Typography variant="h6" sx={{ marginBottom: 1 }}>
                                User Added
                            </Typography>
                           {userAddedGroup.codes
                                .sort((a, b) => a.code.localeCompare(b.code))
                                .map(codeObj => {
                                    const codeKey = codeObj.code;
                                    const status = codeReviewStatus[chartId]?.[codeKey];
                                    const isLoading = status?.isLoading;
                                    const reviewResult = status?.reviewResult;

                                    return (
                                        <Box sx={{ position: 'relative' }}>
                                            <CodeCard
                                                key={codeKey}
                                                code={codeObj}
                                                action={handleMoveToCompleted}
                                                chevronRight
                                                aiIdentifiedConditions={aiIdentifiedConditions}
                                                userSelectedCodes={userSelectedCodes}
                                                handleCodeClick={handleCodeClick}
                                                highlightedText={highlightedText}
                                                reviewResult={reviewResult} // Pass reviewResult to CodeCard
                                            />
                                            {isLoading && (
                                                <Box
                                                    sx={{
                                                        position: 'absolute',
                                                        top: 0,
                                                        left: 0,
                                                        width: '100%',
                                                        height: '100%',
                                                        backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        zIndex: 1,
                                                    }}
                                                >
                                                    <CircularProgress />
                                                    <Typography variant="body1" sx={{ marginLeft: 1 }}>
                                                        Analyzing...
                                                    </Typography>
                                                </Box>
                                            )}
                                        </Box>
                                    );
                                })}
                        </CardContent>
                    </Card>
                )}

                {/* AI Identified Conditions */}
                {aiGroupedGroups.map(group => (
                    <Card key={group.condition}>
                        <CardContent>
                            <ConditionHeader
                                group={group}
                                handleCodeClick={handleCodeClick}
                                highlightedText={highlightedText}
                                isExpanded={true} // Assuming we always want to show the codes
                            />
                            {group.codes
                                .sort((a, b) => a.code.localeCompare(b.code))
                                .map(codeObj => {
                                    const codeKey = codeObj.code;
                                    const status = codeReviewStatus[chartId]?.[codeKey];
                                    const isLoading = status?.isLoading;
                                    const reviewResult = status?.reviewResult;

                                    return (
                                        <Box sx={{ position: 'relative' }}>
                                            <CodeCard
                                            key={codeKey}
                                            code={codeObj}
                                            action={handleMoveToCompleted}
                                            aiIdentifiedConditions={aiIdentifiedConditions}
                                            userSelectedCodes={userSelectedCodes}
                                            handleCodeClick={handleCodeClick}
                                            highlightedText={highlightedText}
                                                reviewResult={reviewResult} // Pass reviewResult to CodeCard
                                            />
                                            {isLoading && (
                                                <Box
                                                    sx={{
                                                        position: 'absolute',
                                                        top: 0,
                                                        left: 0,
                                                        width: '100%',
                                                        height: '100%',
                                                        backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        zIndex: 1,
                                                    }}
                                                >
                                                    <CircularProgress />
                                                    <Typography variant="body1" sx={{ marginLeft: 1 }}>
                                                        Analyzing...
                                                    </Typography>
                                                </Box>
                                            )}
                                        </Box>
                                    );
                                })}
                        </CardContent>
                    </Card>
                ))}

            </Box>
        </ThemeProvider>
    );
};

export default ConditionsList;
