import React, { useState, useEffect } from 'react';
import { Alert, Box, Typography, Grid, Snackbar, Paper } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import theme from '../../styles/theme';
import ConditionsList from './ConditionsList';
import ICDCodeSearch from './ICDCodeSearch';
import FinalizedCodes from './FinalizedCodes';
import { useCodeReviewMutation } from '../../apis/chart';

const CompletedCodesBuilder = ({ chart, highlightedText, handleCodeClick }) => {
    const codedCharts = chart?.coded_charts || [];

    // Separate AI and Human CodedCharts
    const aiCodedChart = codedCharts.find(codedChart => codedChart.source === 'AI') || {};
    const humanCodedChart = codedCharts.find(codedChart => codedChart.source === 'Human') || null;

    const [completedCodes, setCompletedCodes] = useState([]);
    const [userSelectedCodes, setUserSelectedCodes] = useState([]);
    const [alertOpen, setAlertOpen] = useState(false);

    // Modify codeReviewStatus to cache by chartId and code
    const [codeReviewStatus, setCodeReviewStatus] = useState({}); // { [chartId]: { [code]: { isLoading, reviewResult } } }
    const [codeReview] = useCodeReviewMutation();
    const chartId = chart?.uuid

    // Handle adding a code manually
    const handleAddCode = async (code) => {
        // Add the code to userSelectedCodes
        setUserSelectedCodes((prev) => [
            ...prev,
            {
                ...code,
                user_added: true
            }
        ]);

        const codeKey = code.code;

        // Initialize codeReviewStatus for this chartId if it doesn't exist
        setCodeReviewStatus((prevStatus) => ({
            ...prevStatus,
            [chartId]: prevStatus[chartId] || {},
        }));

        // Check if the review result for this chartId and code already exists
        const existingReviewStatus = codeReviewStatus[chartId]?.[codeKey];

        if (existingReviewStatus && existingReviewStatus.reviewResult) {
            // Use the cached result
            setCodeReviewStatus((prevStatus) => ({
                ...prevStatus,
                [chartId]: {
                    ...prevStatus[chartId],
                    [codeKey]: { isLoading: false, reviewResult: existingReviewStatus.reviewResult },
                },
            }));
        } else {
            // Initialize loading state for this code
            setCodeReviewStatus((prevStatus) => ({
                ...prevStatus,
                [chartId]: {
                    ...prevStatus[chartId],
                    [codeKey]: { isLoading: true, reviewResult: null },
                },
            }));

            try {
                const reviewResponse = await codeReview({ code: code, chartId: chart.uuid }).unwrap();

                // Extract the review result for this code
                const reviewItem = reviewResponse.results.find(
                    (item) => item.Code === codeKey
                );

                // Update codeReviewStatus with result
                setCodeReviewStatus((prevStatus) => ({
                    ...prevStatus,
                    [chartId]: {
                        ...prevStatus[chartId],
                        [codeKey]: { isLoading: false, reviewResult: reviewItem },
                    },
                }));
            } catch (error) {
                console.error('Code review failed:', error);
                // Update codeReviewStatus with error
                setCodeReviewStatus((prevStatus) => ({
                    ...prevStatus,
                    [chartId]: {
                        ...prevStatus[chartId],
                        [codeKey]: { isLoading: false, error },
                    },
                }));
                // Optionally show an error message
                setAlertOpen(true);
            }
        }
    };

    useEffect(() => {
        // Populate confirmed codes for FinalizedCodes if there is a human coded chart
        if (humanCodedChart) {
            const confirmedCodes = humanCodedChart.coded_chart_code_references || [];

            const formattedCompletedCodes = confirmedCodes.map(code => ({
                ...code.icd_code
            }));
            setCompletedCodes(formattedCompletedCodes);
        } else {
            setCompletedCodes([]);
        }
    }, [humanCodedChart]);

    // Group AI Identified Conditions
    const groupAICodesByCondition = (codedChart) => {
        if (!codedChart.coded_chart_code_references) return [];

        return codedChart.coded_chart_code_references.reduce((acc, ref) => {
            const conditionName = ref.condition?.name || 'Unknown';
            const highlightText = ref.highlight_text || '';
            const rationale = ref.rationale || '';

            const existingGroup = acc.find(group => group.condition === conditionName);

            if (existingGroup) {
                existingGroup.codes.push(ref.icd_code);
                existingGroup.full_ref.push(ref);

            } else {
                acc.push({
                    condition: conditionName,
                    codes: [ref.icd_code],
                    full_ref: [ref],
                    highlight_text: highlightText,
                    rationale: rationale,
                });
            }
            return acc;
        }, []);
    };

    const aiIdentifiedConditions = groupAICodesByCondition(aiCodedChart);

    const handleMoveToCompleted = (code) => {
        if (!humanCodedChart?.is_processed) {
            setCompletedCodes((prev) => [...prev, {
                ...code,
            }]);
        } else {
            setAlertOpen(true);
        }
    };

    // Handle removing a code from finalized
    const handleRemoveFromCompleted = (code) => {
        if (!chart.is_processed) {
            setCompletedCodes((prev) => prev.filter(item => item.code !== code.code));
        } else {
            setAlertOpen(true);
        }
    };

    const getHighlightedContent = () => {
        const textToHighlight = highlightedText;
        if (!textToHighlight || !chart?.content) return chart?.content;

        const parts = chart.content.split(new RegExp(`(${textToHighlight})`, 'i'));
        return parts.map((part, index) =>
            part.toLowerCase() === textToHighlight.toLowerCase() ? (
                <span key={index} style={{ backgroundColor: theme.palette.highlight.main }}>{part}</span>
            ) : (
                part
            )
        );
    };

    const onDragEnd = (result) => {
        if (!result.destination) return;
        const reorderedCodes = Array.from(completedCodes);
        const [removed] = reorderedCodes.splice(result.source.index, 1);
        reorderedCodes.splice(result.destination.index, 0, removed);
        setCompletedCodes(reorderedCodes);
    };

    // Check AI-identified codes for 'rationale' and 'appropriate' fields
        useEffect(() => {
            // Loop through all identified conditions and their codes
            aiIdentifiedConditions.forEach((condition) => {
                condition.full_ref.forEach((ref) => {
                    const codeKey = ref.icd_code.code;

                    // Initialize codeReviewStatus for this chartId if it doesn't exist
                    setCodeReviewStatus((prevStatus) => ({
                        ...prevStatus,
                        [chartId]: prevStatus[chartId] || {},
                    }));

                    // Check if the review result for this chartId and code already exists
                    const existingReviewStatus = codeReviewStatus[chartId]?.[codeKey];

                    if (existingReviewStatus && existingReviewStatus.reviewResult) {
                        // Skip if already cached
                        return;
                    }

                    // Use the condition data to populate reviewResult
                    const reviewResult = {
                        Code: ref.icd_code,
                        Appropriate: ref.appropriate || "Unknown",
                        Rationale: ref.second_pass_rationale || "No rationale provided",
                        RelevantText: ref.second_pass_relevant_text || "No relevant text available",
                    };

                    // Update codeReviewStatus with the review result
                    setCodeReviewStatus((prevStatus) => ({
                        ...prevStatus,
                        [chartId]: {
                            ...prevStatus[chartId],
                            [codeKey]: { isLoading: false, reviewResult },
                        },
                    }));
                });
            });
        }, [aiIdentifiedConditions, chartId, codeReviewStatus]);


    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box sx={{ padding: 2, border: '1px solid #ccc', borderRadius: 2, height: '100%', overflow: 'hidden' }}>
                <ICDCodeSearch
                    onAddCode={handleAddCode}
                    aiIdentifiedCodes={aiCodedChart.coded_chart_code_references?.map(ref => ref.icd_code) || []}
                />

                <Grid container spacing={2} sx={{ height: 'calc(100% - 80px)' }}>
                    {/* Suggested Codes (AI) */}
                    <Grid item xs={12} md={6} sx={{ height: '100%', overflowY: 'auto' }}>
                        <Typography variant="h6" sx={{ marginLeft: 2, marginBottom: 1 }}>Suggested Codes</Typography>
                        <Box sx={{ padding: 2, border: '1px solid #ccc', backgroundColor: '#f9f9f9' }}>
                            <ConditionsList
                                aiIdentifiedConditions={aiIdentifiedConditions}
                                completedCodes={completedCodes}
                                userSelectedCodes={userSelectedCodes}
                                handleMoveToCompleted={handleMoveToCompleted}
                                handleCodeClick={handleCodeClick}
                                highlightedText={highlightedText}
                                codeReviewStatus={codeReviewStatus}
                                chartId={chartId}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ height: '100%', overflowY: 'auto' }}>
                        <FinalizedCodes
                            completedCodes={completedCodes}
                            handleRemoveFromCompleted={handleRemoveFromCompleted}
                            aiIdentifiedConditions={aiIdentifiedConditions || []}
                            userSelectedCodes={userSelectedCodes}
                            onDragEnd={onDragEnd}
                            codeReviewStatus={codeReviewStatus}
                            chartId={chartId}
                        />
                    </Grid>
                </Grid>

                <Box sx={{ marginTop: 4, height: '20%' }}>
                    <Typography variant="h6">Chart Content</Typography>
                    <Paper elevation={3} sx={{ padding: 3, borderRadius: 2, height: '100%', overflowY: 'auto' }}>
                        <Typography variant="body1" component="div">
                            {getHighlightedContent()}
                        </Typography>
                    </Paper>
                </Box>

                {highlightedText && (
                    <Box
                        sx={{
                            position: 'fixed',
                            bottom: 0,
                            left: 0,
                            width: '400px',
                            padding: 3,
                            backgroundColor: 'white',
                            boxShadow: 3,
                            zIndex: 1000,
                            pointerEvents: 'none',
                        }}
                    >
                        <Typography variant="body1" sx={{ fontStyle: 'italic', fontWeight: 'bold' }} color="textSecondary">
                            Source:
                        </Typography>
                        <br />
                        <Typography
                            variant="body1"
                            sx={{ display: 'inline', backgroundColor: theme.palette.highlight.main }}
                        >
                            {highlightedText}
                        </Typography>
                        <br />
                        <br />
                        <Typography variant="body1" sx={{ fontStyle: 'italic', fontWeight: 'bold' }} color="textSecondary">
                            Rationale:
                        </Typography>
                        <br />
                        <Typography variant="body1">
                            {aiCodedChart.coded_chart_code_references?.find(ref => ref.highlight_text === highlightedText)?.rationale || ''}
                        </Typography>
                    </Box>
                )}

                <Snackbar
                    open={alertOpen}
                    autoHideDuration={6000}
                    onClose={() => setAlertOpen(false)}
                >
                    <Alert onClose={() => setAlertOpen(false)} severity="warning" sx={{ width: '100%' }}>
                        The chart is locked. No changes can be made.
                    </Alert>
                </Snackbar>
            </Box>
        </ThemeProvider>
    );
};

export default CompletedCodesBuilder;
