import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Grid, Typography, Box, Paper, Button, Badge, Chip, Tabs, Tab
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useFetchChartDetailsQuery, useFetchSlowChartDataQuery, useCreateOrUpdateCodedChartMutation } from '../apis/chart';
import CompletedCodesBuilder from './CodesBuilder/CompletedCodesBuilder';
import CoderQueries from './CoderQueries';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import theme from '../styles/theme';
import HighlightedContent from "./HighlightDisplay";
import PDFViewer from "./ChartContent/PDFViewer";
import PatientInfoEditable from "./DetailComponents/EditablePatientDetails";
import StatusToggles from "./ChartContent/StatusToggles";

const ChartDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { data: chart, refetch: refetchDetail } = useFetchChartDetailsQuery(id);
    const { data: slowData } = useFetchSlowChartDataQuery(id);
    const [createOrUpdateCodedChart] = useCreateOrUpdateCodedChartMutation();

    const [highlightedText, setHighlightedText] = useState(null);
    const [completedCodesKey, setCompletedCodesKey] = useState(Date.now());
    const [tabValue, setTabValue] = useState(0);
    const [contentTab, setContentTab] = useState(0);


    useEffect(() => {
        setCompletedCodesKey(Date.now());
    }, [id]);

    const handleCodeClick = (supportingText) => {
        setHighlightedText(supportingText);
    };

    const navigateToChart = (chartId) => {
        navigate(`/chart/${chartId}`);
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleContentTabChange = (event, newValue) => {
        setContentTab(newValue);
    };

    const handleToggleChange = async (field, value) => {
        try {
            const payload = {
                id: humanCodedChart?.uuid,
                chartId: chart.uuid,
                [field]: value
            };

            await createOrUpdateCodedChart(payload);
            refetchDetail();
        } catch (error) {
            console.error(`Error updating coded chart ${field}:`, error);
        }
    };

    // const aiCodedChart = chart?.coded_charts.find(codedChart => codedChart.source === 'AI') || {};
    const humanCodedChart = chart?.coded_charts.find(codedChart => codedChart.source === 'Human') || null;
    const statusColor = humanCodedChart?.is_processed ? "success" : "error";

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box sx={{ width: '100%', height: '100vh', padding: '20px', display: 'flex', flexDirection: 'column' }}>
                <Grid container spacing={2} alignItems="stretch" sx={{ flex: 1 }}>
                    <Grid item xs={12}>
                        <Paper elevation={3} sx={{ padding: 3, borderRadius: 2 }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                {chart && <PatientInfoEditable chart={chart} onSave={refetchDetail}/>}

                                {/* Previous and Next Buttons */}
                                <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 2 }}>

                                        {slowData?.previous_chart && (
                                            <Button
                                                onClick={() => navigateToChart(slowData.previous_chart)}
                                                sx={{ padding: 2, marginRight: 5 }}
                                                variant="contained"
                                                color="secondary"
                                            >
                                                <ArrowBackIosIcon sx={{ marginLeft: 1, color: 'white' }} />
                                                <Typography variant="body1" sx={{ marginLeft: 1, color: 'white' }}>Previous Chart</Typography>
                                            </Button>
                                        )}
                                        {slowData?.next_chart && (
                                            <Button
                                                onClick={() => navigateToChart(slowData.next_chart)}
                                                sx={{ padding: 2 }}
                                                variant="contained"
                                                color="secondary"
                                            >
                                                <Typography variant="body1" sx={{ marginRight: 1, color: 'white' }}>Next Chart</Typography>
                                                <ArrowForwardIosIcon sx={{ marginLeft: 1, color: 'white' }} />
                                            </Button>
                                        )}
                                    </Box>
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>

                    {/* Chart Details and Content */}
                    <Grid item xs={12} md={5} sx={{ display: 'flex', flexDirection: 'column', height: '80vh' }}>
                        <Paper elevation={3} sx={{ padding: 3, borderRadius: 2, flex: 1, overflow: 'auto' }}>

                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                                <Typography variant="h6" gutterBottom>
                                    <strong>Chart Type:</strong> {chart?.chart_type || 'Unknown'}<br />
                                </Typography>

                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography variant="body1" sx={{ marginRight: 1 }}>
                                        <strong>Status:</strong>
                                    </Typography>
                                    <Chip
                                        label={humanCodedChart?.is_processed ? 'Finalized' : 'Unprocessed'}
                                        color={statusColor}
                                        sx={{ fontSize: '1rem' }}
                                    />
                                </Box>
                            </Box>
                                                        <br />

                                                          <Typography variant="h8" gutterBottom>
                                    <strong>File:</strong> {chart?.file_gcs_location || 'Unknown'}<br />
                                </Typography>
                            <br />

                            <StatusToggles
                                chart={humanCodedChart}
                                handleToggleChange={handleToggleChange}
                            />

                            <Tabs value={contentTab} onChange={handleContentTabChange} aria-label="content tabs">
                                <Tab label="Body" />
                                <Tab label="Document" />
                            </Tabs>
                            {contentTab === 0 && chart && (
                                <Typography variant="h6" sx={{ flex: 1 }}>
                                    <HighlightedContent highlightedText={highlightedText} chart={chart} />
                                </Typography>
                            )}
                            {contentTab === 1 && slowData?.gcs_file_link && (
                                <Box sx={{ flex: 1, overflow: 'auto' }}>
                                    <PDFViewer fileUrl={slowData.gcs_file_link} />
                                </Box>
                            )}
                        </Paper>
                    </Grid>

                    {/* Code Builder and Coder Queries */}
                    <Grid item xs={12} md={7} sx={{ display: 'flex', flexDirection: 'column', height: '80vh' }}>
                        <Paper elevation={3} sx={{ padding: 3, borderRadius: 2, flex: 1, overflow: 'auto' }}>
                            <Tabs value={tabValue} onChange={handleTabChange} aria-label="demo detail tabs">
                                <Tab label="Code Builder" />
                                <Tab label={
                                    <Badge color="error">
                                        Coder Queries
                                    </Badge>
                                } />
                            </Tabs>
                           {tabValue === 0  && (
                                <CompletedCodesBuilder
                                    key={completedCodesKey}
                                    chart={chart}
                                    handleCodeClick={handleCodeClick}
                                />
                            )}
                            {tabValue === 1 && chart && (
                                <Box sx={{ padding: 2 }}>
                                    <CoderQueries chartId={chart.uuid} />
                                </Box>
                            )}
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
        </ThemeProvider>
    );
};

export default ChartDetails;
