import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useFetchChartSourcesQuery } from '../apis/chart';

const ChartSourceSelector = ({ onChartSourceChange }) => {
    const { data: sources = [], isLoading } = useFetchChartSourcesQuery();
    const [chartSource, setChartSource] = useState(undefined);

    useEffect(() => {
        // Check if a saved chart source exists in local storage
        let savedChartSource = localStorage.getItem('selectedChartSource');

        // If not, default to the first item in the sources list when loaded
        if (!savedChartSource && sources.length > 0) {
            savedChartSource = sources[0];
            localStorage.setItem('selectedChartSource', savedChartSource);
        }

        if (savedChartSource) {
            setChartSource(savedChartSource);
            onChartSourceChange(savedChartSource);
        }
    }, [sources, onChartSourceChange]);

    const handleChange = (event) => {
        const value = event.target.value;
        setChartSource(value);
        localStorage.setItem('selectedChartSource', value);
        onChartSourceChange(value);
    };

    if (isLoading || sources.length === 0) return null;

    return (
        <FormControl  sx={{ minWidth: 200, mr: 2 }}>
            <InputLabel id="chart-source-label">Chart Source</InputLabel>
            <Select
                labelId="chart-source-label"
                value={chartSource}
                onChange={handleChange}
                label="Chart Source"
            >
                {sources.map((source) => (
                    <MenuItem key={source} value={source}>
                        {source}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default ChartSourceSelector;
