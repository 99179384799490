import React, { useState } from 'react';
import { Box, Typography, TextField, Button, List, ListItem, Paper, Avatar } from '@mui/material';
import {  useCreateCoderQueryMutation, useFetchCoderQueriesQuery, useAddMessageMutation } from '../apis/physicians';
import PersonIcon from '@mui/icons-material/Person';

const CoderQueries = ({ chartId, open, onClose }) => {
    const [query, setQuery] = useState('');
    const { data: queries = [], isLoading: queriesLoading, refetch } = useFetchCoderQueriesQuery(chartId);
    const [createCoderQuery] = useCreateCoderQueryMutation();
    const [addMessage] = useAddMessageMutation();

    // useEffect(() => {
    //     refetch();
    // }, []);

    const handleSendQuery = async () => {
        if (query.trim()) {
            const existingQuery = queries.results;
            if (existingQuery && existingQuery.length > 0) {
                await addMessage({ queryId: existingQuery[0].id, content: query, sender_type: 'user' });
            } else {
                await createCoderQuery({ chart: chartId, messages: [{ content: query, sender_type: 'user' }] });
            }
            setQuery('');
            refetch();
        }
    };

    return (
        <>
            <List sx={{ maxHeight: '300px', overflow: 'auto', marginBottom: 2 }}>
                {queriesLoading ? (
                    <Typography variant="body1">Loading...</Typography>
                ) : (
                    queries.results?.map((q) => (
                        <ListItem key={q.id} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <List sx={{ width: '100%', paddingTop: 5 }}>
                                {q.messages.map((msg) => (
                                    <ListItem key={msg.id} sx={{ justifyContent: msg.sender_type === 'physician' ? 'flex-start' : 'flex-end', paddingTop: 5, alignItems: 'center' }}>
                                        {msg.sender_type === 'physician' && (
                                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: 1 }}>
                                                <Avatar sx={{ width: 24, height: 24, backgroundColor: '#f1c40f' }}>
                                                    <PersonIcon />
                                                </Avatar>
                                                <Typography variant="caption">{msg.sender_name}</Typography>
                                            </Box>
                                        )}
                                        <Paper elevation={2} sx={{ padding: 1, marginBottom: 1, backgroundColor: msg.sender_type === 'physician' ? '#f1f1f1' : '#e0f7fa', maxWidth: '75%' }}>
                                            <Typography variant="body2">{msg.content}</Typography>
                                        </Paper>
                                        {msg.sender_type === 'user' && (
                                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: 1 }}>
                                                <Avatar sx={{ width: 24, height: 24, backgroundColor: '#3498db' }}>
                                                    <PersonIcon />
                                                </Avatar>
                                                <Typography variant="caption">You</Typography>
                                            </Box>
                                        )}
                                    </ListItem>
                                ))}
                            </List>
                        </ListItem>
                    ))
                )}
            </List>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                    label="Type your query..."
                    variant="outlined"
                    fullWidth
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                />
                <Button variant="contained" color="primary" onClick={handleSendQuery} sx={{ marginLeft: 1 }}>
                    Send
                </Button>
            </Box>
        </>
    );
};

export default CoderQueries;
