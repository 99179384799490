import React, { useState, useEffect, useCallback } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import {
    useFetchChartsQuery,
    useDeleteChartMutation,
    useFetchChartCountsQuery,
} from '../apis/chart';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import LaunchIcon from '@mui/icons-material/Launch';
import DeleteIcon from '@mui/icons-material/Delete';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Tooltip
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { CheckCircle, Error } from '@mui/icons-material';
import { green, red } from '@mui/material/colors';

const extractFilenameDetails = (filename) => {
    const match = filename.match(/charts\/([\w-]+)\.pdf_pages_(\d+)-(\d+)\.pdf/);
    if (!match) return { uuid: '', startPage: 0, endPage: 0 };

    const [, uuid, startPage, endPage] = match;
    return { uuid, startPage: parseInt(startPage, 10), endPage: parseInt(endPage, 10) };
};


const filenameComparator = (v1, v2) => {
    const { uuid: uuid1, startPage: start1 } = extractFilenameDetails(v1);
    const { uuid: uuid2, startPage: start2 } = extractFilenameDetails(v2);

    if (uuid1 < uuid2) return -1;
    if (uuid1 > uuid2) return 1;
    return start1 - start2;
};

const ChartList = ({ status, chartSource }) => {
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 50,
    });

    const { data, error, isLoading, isFetching, refetch } = useFetchChartsQuery({
        page: paginationModel.page + 1,
        pageSize: paginationModel.pageSize,
        status,
        chartSource
    });
    const [deleteChart] = useDeleteChartMutation();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(null);

    const handlePaginationModelChange = useCallback(
        (newModel) => {
            if (newModel.pageSize !== paginationModel.pageSize) {
                setPaginationModel({ ...newModel, page: 0 });
            } else {
                setPaginationModel(newModel);
            }
        },
        [paginationModel.pageSize]
    );

    useEffect(() => {
        refetch();
    }, [paginationModel, refetch]);

    const handleDelete = async () => {
        await deleteChart(selectedId);
        handleClose();
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedId(null);
    };

    const handleOpenDetails = (id) => {
        navigate(`/chart/${id}`);
    };

    const handleOpenDetailsNewTab = (id) => {
        window.open(`/chart/${id}`, '_blank');
    };

    const extractConditions = (codedChart) => {
        if (!codedChart || !codedChart.coded_chart_code_references) return '';
        return codedChart.coded_chart_code_references
            .filter(code => code.condition)
            .map(code => code.condition.name)
            .join(', ');
    };

    const baseColumns = [
        {
            field: 'date_of_service',
            headerName: 'DOS',
            flex: 1,
            renderCell: (params) => dayjs(params.row.date_of_service).format('MM-DD-YYYY'),
        },
        // {
        //     field: 'chart_source',
        //     headerName: 'Source',
        //     flex: 1,
        // },
        {
            field: 'patient',
            headerName: 'Patient',
            flex: 1,
        },
        {
            field: 'file_gcs_location',
            headerName: 'Filename',
            flex: 2,
            valueGetter: (value, row) => row.file_gcs_location,
            sortComparator: filenameComparator,
        },
        {
            field: 'conditions',
            headerName: 'Identified Conditions',
            flex: 2,
        },
    ];

    const columns = [
        ...baseColumns,
        ...(status === 'documentation_deficient' ? [
            {
                field: 'out_of_scope_reason',
                headerName: 'Out of Scope Reason',
                flex: 1,
            }
        ] : []),
        ...(status === 'completed' ? [
            {
                field: 'submittable',
                headerName: 'Submittable',
                width: 120,
                sortable: true,
                renderCell: (params) => (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
                        {params.row.submittable ? (
                            <CheckCircle sx={{ color: green[500] }} />
                        ) : (
                            <Error sx={{ color: red[500] }} />
                        )}
                    </Box>
                ),
            },
        ] : []),
        {
            field: 'open',
            headerName: 'Open',
            sortable: false,
            width: 80,
            renderCell: (params) => (
                <IconButton onClick={() => handleOpenDetails(params.id)}>
                    <OpenInNewIcon />
                </IconButton>
            ),
        },
    {
        field: 'openNewTab',
        headerName: 'New Tab',
        sortable: false,
        width: 80,
        renderCell: (params) => (
            <Tooltip title="New Tab">
                <IconButton onClick={() => handleOpenDetailsNewTab(params.id)} aria-label="Open in New Tab">
                    <LaunchIcon />
                </IconButton>
            </Tooltip>
        ),
    },
        {
            field: 'delete',
            headerName: 'Delete',
            sortable: false,
            width: 80,
            renderCell: (params) => (
                <IconButton
                    onClick={() => {
                        setSelectedId(params.id);
                        setOpen(true);
                    }}
                >
                    <DeleteIcon />
                </IconButton>
            ),
        },
    ];

    const rows = data?.data?.map((chart) => {
        const aiCodedCharts = chart.coded_charts.filter(chart => chart.source === 'AI');
        const humanCodedCharts = chart.coded_charts.filter(chart => chart.source === 'Human');
        const latestAICodedChart = aiCodedCharts[aiCodedCharts.length - 1];
        const latestHumanCodedChart = humanCodedCharts[humanCodedCharts.length - 1];
        return {
            id: chart.uuid,
            date_of_service: chart.date_of_service,
            chart_source: chart.source,
            file_gcs_location: chart.file_gcs_location,
            patient: chart.patient ? `${chart.patient.name}  (${chart.patient.gender})` : "Unknown",
            chart_type: chart.chart_type,
            conditions: extractConditions(latestAICodedChart),
            is_processed: latestHumanCodedChart?.is_processed || false,
            coded_by: latestHumanCodedChart?.coder?.username || 'Unknown',
            documentation_deficiency: latestHumanCodedChart?.documentation_deficiency || false,
            submittable: latestHumanCodedChart?.submittable || false,
            out_of_scope_reason: latestHumanCodedChart?.out_of_scope_reason || "",
        };
    }) || [];

    if (isLoading || isFetching) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    return (
        <>
            <DataGrid
                pagination
                rows={rows}
                columns={columns}
                rowsPerPageOptions={[25, 50, 100]}
                paginationMode="server"
                rowCount={data?.total || 0}
                paginationModel={paginationModel}
                onPaginationModelChange={handlePaginationModelChange}
                pageSizeOptions={[25, 50, 100]}
     initialState={{
      sorting: {
        sortModel: [
          { field: 'file_gcs_location', sort: 'asc' },
                      { field: 'patient', sort: 'asc' },

        ],
      },
    }}

            />

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{'Confirm Deletion'}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this chart? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} color="primary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

const TabbedCharts = ({ chartSource }) => {
    const [tabValue, setTabValue] = useState(0);
    const {data: chartCounts, isLoading, error} = useFetchChartCountsQuery(chartSource);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    if (isLoading) return <div>Loading counts...</div>;
    if (error) return <div>Error loading counts: {error.message}</div>;

    const {newCount, completedCount, blockedCount, deficientCount} = chartCounts || {
        newCount: 0,
        completedCount: 0,
        blockedCount: 0,
        deficientCount: 0,
    };

    return (
        <Box>
            <Tabs value={tabValue} onChange={handleTabChange}>
                <Tab label={`New (${newCount})`}/>
                <Tab label={`Completed (${completedCount})`}/>
                <Tab label={`Blocked (${blockedCount})`}/>
                <Tab label={`Out of Scope (${deficientCount})`}/>
            </Tabs>

            {tabValue === 0 && <ChartList status="new" chartSource={chartSource}/>}
            {tabValue === 1 && <ChartList status="completed" chartSource={chartSource}/>}
            {tabValue === 2 && <ChartList status="blocked" chartSource={chartSource}/>}
            {tabValue === 3 && <ChartList status="documentation_deficient" chartSource={chartSource}/>}
        </Box>
    );
};

export default TabbedCharts;
